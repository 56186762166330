import { render, staticRenderFns } from "./shop.vue?vue&type=template&id=1c82b4d8&scoped=true"
import script from "./shop.vue?vue&type=script&lang=js"
export * from "./shop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c82b4d8",
  null
  
)

export default component.exports